<template>
  <div class='app-container'>
    <el-page-header @back="goBack" content="角色管理"></el-page-header>

    <el-divider class="margin20"></el-divider>

    <!-- <el-alert title="创建策略需要先选择相应的账号类型，再对其类型创建相应的权限策略" type="info" :closable='false' show-icon>
    </el-alert> -->

    <el-tabs v-model="activeName" @tab-click="tabClickHandle" class="el-tabs_self">
      <!-- <el-tab-pane v-for="item in roles" :key="item.roleId" :label="item.description" :name="item.description">
          <create-auth :roleId='item.roleId'></create-auth>
      </el-tab-pane> -->
<!--      <el-tab-pane label="添加角色" name="first">-->
<!--        <create-auth v-if="selected == 0"></create-auth>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="已有角色" name="second" >-->
<!--        <existed-auth v-if="selected == 1"></existed-auth>-->
<!--      </el-tab-pane>-->
      <el-tab-pane label="添加院校角色" name="first">
        <create-school-auth v-if="selected == 0"></create-school-auth>
      </el-tab-pane>
      <el-tab-pane label="已有院校角色" name="second">
        <existed-school-auth v-if="selected == 1"></existed-school-auth>
      </el-tab-pane>
    </el-tabs>
  </div>


</template>

<script>
// import CreateAuth from '@/components/authority/CreateAuth'
// import ExistedAuth from '@/components/authority/ExistedAuth'
import CreateSchoolAuth from '@/components/authority/CreateSchoolAuth'
import ExistedSchoolAuth from "@/components/authority/ExistedSchoolAuth.vue";
export default {
  data() {
    return {
      activeName: 'first',
      roles: [],
      selected:0
    }
  },
  created() {
    // this.getPreviewRole();
  },
  methods: {
    getPreviewRole() {
      this.roles = [{
        description : "角色权限列表",
        roleId : 7}];
      // this.$api.authority.getPreviewRole()
      //     .then(res => {
      //         if (res.data.code == 200) {
      //             this.roles = res.data.data;
      //             console.log(this.roles);
      //             // this.activeName = this.roles[0].description;
      //         }
      //     }).catch(err => {
      //         this.$message.warning('服务器维护');
      //     })
    },
    goBack() {
      this.$router.push({
        name: 'strategySchool'
      })
    },
    tabClickHandle(tab,event){
      console.log(tab.index);
      this.selected = tab.index
    },
    // changeActiveName1(){
    //     this.selected = 1;
    //     console.log(99999999999)
    // },
    // changeActiveName2(){
    //     this.selected = 2;
    //     console.log(1234143)
    // }
  },
  components: {
    // CreateAuth,
    // ExistedAuth,
    CreateSchoolAuth,
    ExistedSchoolAuth
  }
}
</script>

<style scoped>
.el-tabs_self {
  margin-top: 10px;
}

.authBox .el-card__body {
  height: calc(100vh - 300px);
  overflow: auto;
}
</style>

<style>
.margin20.el-divider--horizontal {
  margin: 20px 0px;
}

.selectedAuth .el-card__body {
  padding: 0px;
  padding-bottom: 40px;
  overflow: auto;
}

.authBox .el-card__body {
  height: calc(100vh - 350px);
  overflow: auto;
}
</style>